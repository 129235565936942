import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    SUBSCRIPTION_POPUP_AND_BANNER_HP
} from 'Component/ProductActions/ProductActions.config';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { ProductType } from 'Type/ProductList';
import { toggleScroll } from 'Util/Browser';
import browserHistory from 'Util/History';

import './HpSubscriptionPopup.style';
/** @namespace Scandipwa/Component/HpSubscriptionPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer.product
});

/** @namespace Scandipwa/Component/HpSubscriptionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Scandipwa/Component/HpSubscriptionPopup/Container */
export class HpSubscriptionPopupContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        contentId: PropTypes.string.isRequired
    };

    handleWrapperClick = this.handleWrapperClick.bind(this);

    handleCloseClick = this.handleCloseClick.bind(this);

    hpWrapper = document.getElementById('hp-mps-widget-wrapper');

    closeBtn = document.getElementById('hp-mps-widget-close');

    componentDidMount() {
        const {
            contentId,
            product: {
                attributes: {
                    part_no: { attribute_value } = {}
                } = {}
            } = {}
        } = this.props;

        const hpScript = document.getElementById('mps-widget');

        if (!hpScript) {
            const scriptWrapper = document.getElementById('hp-script-wrapper');
            const scriptNode = document.createElement('script');
            const scriptSrc = `https://mps.hpconfigure.com/mps/getScript?company_id=17&sku=${attribute_value}`;
            scriptNode.setAttribute('src', scriptSrc);
            scriptNode.setAttribute('id', 'mps-widget');
            scriptWrapper.appendChild(scriptNode);
        } else {
            hpScript.src = `https://mps.hpconfigure.com/mps/getScript?company_id=17&sku=${attribute_value}`;
        }

        if (contentId === SUBSCRIPTION_POPUP_AND_BANNER_HP) {
            const hpWrapper = document.getElementById('hp-mps-widget-wrapper');
            const widgetContent = document.getElementById('hp-mps-widget-content');

            if (hpWrapper && widgetContent) {
                hpWrapper.appendChild(widgetContent);
            }
        }

        this.hpWrapper.style = 'display: flex';
        this.hpWrapper.addEventListener('click', this.handleWrapperClick);
        this.closeBtn.addEventListener('click', this.handleCloseClick);
    }

    componentWillUnmount() {
        const {
            contentId
        } = this.props;

        if (contentId === SUBSCRIPTION_POPUP_AND_BANNER_HP) {
            const hpBannerWrapper = document.getElementById('banner-node-wrapper');
            const widgetContent = document.getElementById('hp-mps-widget-content');

            if (hpBannerWrapper && widgetContent) {
                hpBannerWrapper.appendChild(widgetContent);
            }
        }

        this.hpWrapper.style = 'display: none';
        this.hpWrapper.removeEventListener('click', this.handleWrapperClick, false);
        this.closeBtn.removeEventListener('click', this.handleCloseClick, false);
    }

    handleWrapperClick(e) {
        if (e.target.id === 'hp-mps-widget-wrapper') {
            this.hidePopUp();
        }
    }

    handleCloseClick() {
        this.hidePopUp();
    }

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
    }

    hidePopUp() {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;

        this.unfreezeScroll();
        goToPreviousNavigationState();
        hideActiveOverlay();
        browserHistory.goBack();
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HpSubscriptionPopupContainer);
